
.container{
    width: 70%;
    text-align: center;
    margin: auto;
    text-align: center;
}

.card{
    width: auto;
    margin: 5% ;
    color: #000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cardReverse {
    flex-flow: row-reverse;
}

.textContainer{
    padding-top: 10px;
    word-spacing: -1.3px; 
    line-height: 26px;
    text-align: start;
   
  /* background: linear-gradient(90deg, rgba(207,202,130,1) 3%, rgba(232,64,31,0) 63%, rgba(217,214,228,0.5214460784313726) 100%); */
    transition: all .3s;
    /* border-radius: 48px; */
    color: white;
    width: 100%;
    flex: 2;
    position: relative;
}

.textContainer::after {
    position: absolute;
    top: 0;
    content: '';
    left: 0;
    height: 5px;
    width: 40%;
    background-color: white;
    transition: all .4s;
}

.card:hover > .textContainer::after{
    width: 100%;
}

/* .textContainer::before {
    position: absolute;
    top: 1;
    content: '';
    right: 0;
    height: 1px;
    width: 100%;
    background-color: rgb(184, 177, 177);
} */

/* .textContainer:hover{
    border-radius: 28px;
} */

.textContainer p {
    font-weight: 600;
    word-spacing: .5rem;
    margin-top: 20px
}

.center{
    text-align: center;
    margin-top: 175px;
    font-size: 65px;
}

.imageContainer {
    flex: 2;
   
}

.imageContainerEnd{
    text-align: end;
}
.imageContainerStart {
    text-align: start;
}

.description {
    word-spacing: -0.3px !important;
    /* color: red !important; */
    text-align:start
   
}
.cardImage{ 
    border-radius: 30px;
    height: 750px;
}

.cardImageLeft {
   
    transform: rotate(-2deg);
    transition: all 0.3s;
}

.cardImageRight{
   
    transform: rotate(2deg);
    transition: all 0.3s;
}

.cardImageRight:hover{
    transform: rotate(-2deg);
}

.cardImageLeft:hover{
    transform: rotate(2deg);
}

.animationDiv {
    overflow: hidden;
}

.animationDiv > p {
    color: rgb(182, 177, 177);
}

@media screen and (max-width : 1550px){
    .container{
        width: 80%;
    } 

}

@media screen and (max-width : 1250px){
    .container{
        width: 90%;
    } 

}



@media screen and (max-width : 1150px){
    .container{
        width: 100%;
    } 

    .description {
        font-size: 15px;
    }

   
}

@media screen and (max-width : 950px){
    

    .textContainer {
        width: 80%;
        margin: 0px;
        padding: 0px;
    }

    .card {
        margin-top: 50px; 
        margin-bottom: 25px;
        padding: 40px;  
        text-align: start;
        border-radius: 20px;
        flex-direction: column;
    }

    .textContainer {
        margin-bottom: 30px;
    }


    @media screen and (max-width : 450px){
        .cardImage{ 
            border-radius: 30px;
            height: 330px;
        }
        .center {
            font-size: 30px;
        }
        .imageContainerEnd{
            text-align: center;
        }
        .imageContainerStart {
            text-align: center;
        }
     }
   
}