.topNav {
    /* background-color: #4234be; */
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding-left: 20px;
    transition: all .3s;
    position: sticky;
    top: 0;
    z-index: 100;

}

.btn{
    outline: none;
    border: none;
    border: #f0e3c4 1px solid;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: transparent;
    /* margin-top: -5px; */
}

.topNavScrollView {
    background: rgb(28, 27, 56)
        /* background-color:#FEC129; */

}

.topNavLi :hover {
    color: #FEC129;
    /* background-color: rgb(var(--txts,var(--color_14)));; */
}


.topNavRight {
    justify-content: flex-end;
    display: flex;
    list-style: none;
    flex: 1;
    align-items: flex-end;
    flex: 1;
    font-size: 18px;
    padding: 5px;
    font-weight: 600;
    font-family: questrial, sans-serif;
}

.topNavLi {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: auto
        /* style: auto */
}

.topNavLi li {
    list-style: none;
    padding: 0px 10px;
    font-size: 15px;
}

/* 
.topNavRight button {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid black;
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    touch-action: manipulation;
}
.topNavRight button ::after{
   content: "";
   background-color: #ffe54c;
   width: 100%;
   z-index: -1;
   position: absolute;
   height: 100%;
   top: 7px;
   left: 7px;
   transition: 0.2s;
}
.topNavRight button :hover:after {
  top: 0px;
  left: 0px;
 } */