.mainDiv {
  /* background-color: #FEC129; */
  overflow: hidden;
  font-weight: 400;

}

#head {
  font-size: 2.8vw;
  margin-top: 105px;
  font-weight: 500;
}

#main_head {
  /* margin-top: 5px;  */
  font-size: 3.5vw;
  margin-top: 30px;
  margin-bottom: 50px;
  font-weight: 500;
}

#span {
  font-size: 21px;
  font-weight: 500;
  color: #a17f28;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}

.topContainer {
  display: flex;
  justify-content: space-around;
}

.topLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
    flex: 2;
}

.topRightContainer{
    height: 650px;
    width: 150px;
    flex: 2;
    text-align: center;
    margin-top: 50px;
   
}
.topRightContainer > img {
    object-fit: fill;
    height: 650px;
}

.topRightContainerAnimation {
    object-fit: fill;
    height: 700px;
}

@media only screen and (max-width: 1100px) {
  #main_head {
    font-size: 5vw;
  }

  #head {
    font-size: 4vw;
  }
}
@media only screen and (max-width: 700px) {

    .topRightContainer  {
        display: none;
      }
 }


@media only screen and (max-width: 400px) {
  #main_head {
    font-size: 7vw;
  }

  #head {
    font-size: 6vw;
  }
  
}
